import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {HorizontalPosition, LoaderService, VerticalPosition, VisibilityState} from '@page2flip/core/common';
import {Subscription} from 'rxjs';

import {EventListener} from '../../core/services/event-listener.service';
import {SidePanelService} from './side-panel.service';

/**
 * Component that represents the side panel of the viewer.
 */
@Component({
  selector: 'p2f-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent implements OnInit, OnDestroy {

  /** Horizontal position of the side panel. */
  @Input() horizontalPosition: HorizontalPosition;

  /** Vertical position of the side panel. */
  @Input() verticalPosition: VerticalPosition;

  @Input() toolbarHidden: boolean = false;

  /** Reference to the content container of the side panel. */
  @ViewChild('content', {read: ViewContainerRef, static: true}) private contentContainer: ViewContainerRef;

  /** Subscriptions of the component. */
  private readonly subscriptions: Subscription[] = [];

  /**
   * Constructor of the component.
   *
   * @param element   Wrapper around a native element inside of a View.
   * @param events    Service to control events.
   * @param loader    Service to control the loader component.
   * @param renderer  Service that provides a low-level interface for modifying the UI.
   * @param sidePanel Service to control the side panel behavior.
   */
  constructor(private element: ElementRef,
              private events: EventListener,
              private loader: LoaderService,
              private renderer: Renderer2,
              private sidePanel: SidePanelService) {
  }

  /**
   * Handles the component subscriptions and creates an instance of the feature component inside the content container of the side panel.
   */
  ngOnInit() {
    this.events.disableVerticalSwipe();

    this.renderer.addClass(this.element.nativeElement, this.horizontalPosition);
    this.renderer.addClass(this.element.nativeElement, this.verticalPosition === 'top' ? 'bottom' : 'top');
    if (this.toolbarHidden) {
      this.renderer.addClass(this.element.nativeElement, 'hiddenToolbar');
    }

    this.subscriptions[this.subscriptions.length] = this.sidePanel.visibility().subscribe(visibility => visibility === VisibilityState.Visible && this.loader.stop());

    this.subscriptions[this.subscriptions.length] = this.sidePanel.width().subscribe(width => {
      this.renderer.removeClass(this.element.nativeElement, 'collapsed');
      this.renderer.removeClass(this.element.nativeElement, 'expanded');
      this.renderer.addClass(this.element.nativeElement, width);
    });

    this.subscriptions[this.subscriptions.length] = this.sidePanel.data().subscribe(data => {
      if (data.componentFactory) {
        this.contentContainer.clear();
        this.contentContainer.createComponent(data.componentFactory);
      }
    });
  }

  /**
   * Removes the feature component from the content container of the side panel.
   */
  ngOnDestroy() {
    this.events.enableVerticalSwipe();
    this.contentContainer.clear();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


}
