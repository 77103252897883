import {DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges, OnDestroy,
  OnInit, Output,
  SecurityContext,
  ViewChild
} from '@angular/core';
import {DomSanitizer, HammerGestureConfig} from '@angular/platform-browser';
import {HotspotService, WINDOW} from '@page2flip/core';
import {Configuration, DialogType, Hotspot, PanelType, Position} from '@page2flip/core/common';
import {EmbedVideoService} from 'ngx-embed-video';
import * as SVG from 'svg.js';

import {ConfigurationHolder} from '../../core/services/configuration-holder.service';
import {Constants} from '../../core/services/constants.service';
import {Designer} from '../../core/services/designer.service';
import {DocumentService} from '../../core/services/document.service';
import {FeatureLoader} from '../../core/services/feature-loader.service';
import {Storage} from '../../core/services/storage.service';
import {environment} from '../../../environments/environment';
import {forkJoin, fromEvent, Subscription} from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';
import {TrackingService} from "../../core/services/tracking.service";
import {PageDimensions} from "@page2flip/core/common/src/lib/interfaces/dimensions.interface";
import 'video.js/dist/video-js.css';
import 'video.js';
import lightGallery from "lightgallery";
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-transitions.css';
import {HttpClient} from "@angular/common/http";
import lgVideo from "lightgallery/plugins/video";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import {LightGallery} from "lightgallery/lightgallery";
import {WebsocketService} from "../../shared/services/websocket.service";
import {ArticleService} from "../../shared/services/article.service";

@Component({
  selector: 'p2f-hotspot-layer',
  templateUrl: './hotspot-layer.component.html',
  styleUrls: ['./hotspot-layer.component.css']
})
export class HotspotLayerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  /** Supported video providers. */
  private readonly videoProviders: string[] = [
    'youtube.com',
    'youtu.be',
    'vimeo.com',
    'dailymotion.com',
    'dai.ly'
  ];

  /** Supported URI schemes for external links. */
  private readonly uriSchemes: string[] = [
    'ftp://',
    'http://',
    'https://',
    'mailto:',
    'sms:',
    'tel:'
  ];

  @Input() pageNumbers: number[];

  @Output() swipeLeft: EventEmitter<any> = new EventEmitter<any>();
  @Output() swipeRight: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('pages') private pagesElementRef: ElementRef;

  private configuration: Configuration;
  private element: HTMLDivElement;
  private hotspots: Hotspot[];
  private pageDimensions: PageDimensions[];
  private pagesElement: HTMLDivElement;
  private scale: number;
  private tooltip: HTMLDivElement;
  private visiblePages: number[];
  private zoomElementPosition: Position;
  private isViewerInIframe: boolean;
  private lightgallery: LightGallery;
  private messageEventsSubscription: Subscription;
  apiUrl = environment.apiUrl;

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(WINDOW) private window: any,
    private config: ConfigurationHolder,
    private designer: Designer,
    private doc: DocumentService,
    private elementRef: ElementRef,
    private feature: FeatureLoader,
    private trackingService: TrackingService,
    private hotspot: HotspotService,
    private sanitizer: DomSanitizer,
    private storage: Storage,
    private video: EmbedVideoService,
    private httpClient: HttpClient,
    private articleService: ArticleService,
    private websocketService: WebsocketService) {
    this.configuration = config.config;
    this.element = elementRef.nativeElement;
    this.hotspots =
      window.JSON.parse(window.sessionStorage.getItem('hotspots')) ||
      window['data'].hotspots;
  }

  ngOnInit() {
    this.subscribeToWebsocketEvents();
    this.subscribeHotspotEvent();

    this.doc.pageDimensions.subscribe(
      dimensions => (this.pageDimensions = dimensions)
    );
    this.doc.visiblePages.subscribe(pages => (this.visiblePages = pages));
    this.isViewerInIframe = window.location.href.includes('iframe');
  }

  ngAfterViewInit() {
    this.pagesElement = this.pagesElementRef.nativeElement;
    const storedData = this.window.localStorage.getItem(this.doc.publication.id);
    const data = JSON.parse(storedData);
    const checkoutData = data?.checkout;
    if (data?.checkout) {
      localStorage.setItem('checkout', 'false');
      this.articleService.changeArticleState(this.apiUrl, window['data'].publication.assetId, checkoutData.id, 'available').subscribe(() => {
        const index = this.hotspots.findIndex(hotspot => hotspot.id === checkoutData.id);
        if (index !== -1) {
          this.hotspots[index].action.state = 'available';
          this.setDimensions();
          this.setPosition();
          this.removeHotspots();
          this.renderHotspots();
        }
      });
    } else {
      this.setDimensions();
      this.setPosition();
      this.removeHotspots();
      this.renderHotspots();
    }
    this.doc.zoomElementPosition.subscribe(position => {
      this.zoomElementPosition = position;
      this.setPosition();
    });

    if (this.config.touchMode) {
      const hammerConfig = new HammerGestureConfig();
      const pageContainer = this.document.querySelectorAll('p2f-hotspot-layer > .pages')[0];
      const hammer = hammerConfig.buildHammer(pageContainer);
      fromEvent(hammer, 'swipe')
        .pipe(takeWhile(() => true))
        .subscribe((res: any) => {
          if (this.doc.currentZoomFactor < 1.001) {
            res.deltaX < 0 ? this.swipeLeft.emit() : this.swipeRight.emit();
          }
        });
    }
  }

  ngOnChanges() {
    if (this.pageDimensions) {
      requestAnimationFrame(() => {
        this.setDimensions();
        this.setPosition();
        this.removeHotspots();
        this.renderHotspots();
      });
    }
  }

  ngOnDestroy() {
    if (this.messageEventsSubscription) {
      this.messageEventsSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.removeHotspots();
  }

  @HostListener('mouseover', ['$event'])
  @HostListener('mousemove', ['$event'])
  onMouseOver(event: MouseEvent | any) {
    this.removeTooltip();

    if (event.target.nodeName === 'path') {
      // const id = event.target.dataset.id;
      const id: string = this.getDataAttribute(event.target, 'id');
      const hotspot: Hotspot = this.getHotspot(id);
      if (hotspot.action.quantity !== 0 && hotspot.action.state !== 'unavailable') {
        this.addTooltip(event, hotspot);
      }
    }
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.removeTooltip();
  }

  @HostListener('click', ['$event'])
  @HostListener('tap', ['$event'])
  onClick(event: MouseEvent | TouchEvent | any) {
    if (event.target.nodeName === 'path') {
      // const id = event.target.dataset.id;
      const id: string = this.getDataAttribute(event.target, 'id');
      const hotspot: Hotspot = this.getHotspot(id);
      this.removeTooltip();
      if (hotspot.action.type === 'article') {
        if (hotspot.action.quantity !== 0 && hotspot.action.state !== 'unavailable') {
          this.articleService.changeArticleState(this.apiUrl, window['data'].publication.assetId, hotspot.id.toString(), 'unavailable').subscribe();
          this.openTarget(hotspot, event);
        }
      } else {
        this.openTarget(hotspot, event);
      }
    }
  }

  // TODO: Remove when IE/Edge supports element.dataset
  // Neither IE nor Edge currently supports the element.dataset attribute.
  // Hence we have to use this method instead for the time being.
  private getDataAttribute(element, name: string) {
    for (const i in element.attributes) {
      if (element.attributes[i].name === 'data-' + name) {
        return element.attributes[i].value;
      }
    }
  }

  private getHotspot(id: string): Hotspot {
    for (const i in this.hotspots) {
      if (this.hotspots.hasOwnProperty(i)) {
        const hotspot: Hotspot = this.hotspots[i];

        if (hotspot.id === id) {
          return hotspot;
        }
      }
    }
  }

  private setDimensions() {
    this.document
      .querySelectorAll('p2f-hotspot-layer > .pages > .page')
      .forEach((pageElement: HTMLDivElement) => {
        const pageNumber: number = parseInt(pageElement.getAttribute('page'), 0);
        pageElement.style.width = this.doc.pageDimensionForPage(pageNumber).width + 'px';
        pageElement.style.height = this.doc.pageDimensionForPage(pageNumber).height + 'px';
      });

    if (this.pagesElement) {
      this.pagesElement.style.width =
        this.doc.scaledWidth() + 'px';
      this.pagesElement.style.height =
        this.doc.scaledHeight() + 'px';
    }
  }

  private setPosition() {
    if (this.pagesElement) {
      this.pagesElement.style.left =
        Math.floor(
          (this.element.clientWidth - this.pagesElement.clientWidth) / 2
        ) + 'px';
      this.pagesElement.style.top =
        Math.floor(
          (this.element.clientHeight - this.pagesElement.clientHeight) / 2
        ) + 'px';
    }
  }

  private async renderHotspots() {
    if (this.doc.turnJsEnabled) {
      await new Promise(resolve =>
        setTimeout(resolve, Constants.pageFlipAnimationDuration)
      );
    }

    this.document
      .querySelectorAll('p2f-hotspot-layer > .pages > .page')
      .forEach((pageElement: HTMLDivElement) => {
        pageElement.innerHTML = '';

        const pageNumber: number = parseInt(
          pageElement.getAttribute('page'),
          0
        );
        const svg: any = SVG(pageElement).size('100%', '100%');

        if (this.doc.publication.pages?.length > 0) {
          const page = this.doc.publication.pages.find(publicationPage => publicationPage.pageNumber === pageNumber);
          this.scale = (this.doc.pageDimensionForPage(pageNumber).width / page.width +
            this.doc.pageDimensionForPage(pageNumber).height / page.height) / 2;
        } else {
          this.scale =
            (this.doc.pageDimensionForPage(pageNumber).width / this.doc.publication.pdf.width +
              this.doc.pageDimensionForPage(pageNumber).height / this.doc.publication.pdf.height) /
            2;
        }

        this.hotspots
          .filter(hotspot => hotspot.page === pageNumber)
          .forEach(hotspot => {
            const path: string = this.hotspot.scalePath(
              hotspot.path,
              this.scale,
              1
            );
            const svgPath: any = svg.path(path);
            const boundingBox: any = svgPath.bbox();

            svgPath.attr('data-id', hotspot.id);
            svgPath.attr('data-display', hotspot.display);
            svgPath.attr('data-type', hotspot.action.type);
            svgPath.fill('transparent');
            if (hotspot.action.type === 'article' && (hotspot.action.quantity === 0 || hotspot.action.state !== 'available')) {
              if (!hotspot.action.targetDisabled) {
                svgPath.fill({
                  color: 'grayscale(100%)',
                  opacity: 0.1
                });
                return
              } else {
                const inlineElement: HTMLDivElement = this.document.createElement(
                  'div'
                );
                inlineElement.classList.add(hotspot.action.type);
                inlineElement.innerHTML = this.sanitizer.sanitize(
                  SecurityContext.NONE,
                  this.createInlineHtml(hotspot, true, false)
                );
                inlineElement.style.width = '100%';
                inlineElement.style.display = 'grid';
                inlineElement.style.position = 'absolute';
                inlineElement.style.top = boundingBox.y + 'px';
                inlineElement.style.left = boundingBox.x + 'px';
                inlineElement.style.maxWidth = boundingBox.width + 'px';
                inlineElement.style.height = boundingBox.height + 'px';
                pageElement.appendChild(inlineElement);
                return;
              }
            }
            switch (hotspot.display) {
              case 'box':
              case 'border':
              case 'hover':
                svgPath.fill({
                  color: hotspot.color,
                  opacity: 0.5
                });

                svgPath.stroke({
                  color: hotspot.color,
                  opacity: 0.75,
                  width: 1
                });
                break;

              case 'icon':
                const svgImage: any = svg.image(
                  `./icons/hotspot-${hotspot.action.type}.svg`, // Use local icons, but if missing, use deployed ones
                  boundingBox.width,
                  boundingBox.height
                );
                svgImage.error(() => svgImage.load(`${environment.deployUrl}assets/templates/hotspot-${hotspot.action.type}.svg`));
                svgImage.attr('x', boundingBox.x);
                svgImage.attr('y', boundingBox.y);
                svgImage.attr('opacity', 0.4);
                break;

              case 'inline':
                const inlineElement: HTMLDivElement = this.document.createElement(
                  'div'
                );
                inlineElement.classList.add(hotspot.action.type);
                inlineElement.innerHTML = this.sanitizer.sanitize(
                  SecurityContext.NONE,
                  this.createInlineHtml(hotspot, true)
                );
                inlineElement.style.width = '100%';
                inlineElement.style.display = 'grid';
                inlineElement.style.position = 'absolute';
                inlineElement.style.top = boundingBox.y + 'px';
                inlineElement.style.left = boundingBox.x + 'px';
                inlineElement.style.maxWidth = boundingBox.width + 'px';
                inlineElement.style.height = boundingBox.height + 'px';
                pageElement.appendChild(inlineElement);
                if (hotspot.action.type === 'gallery') {
                  this.createInlineGallery(hotspot);
                }

                // remove inline image hotspot path
                if (hotspot.action.type === 'image') {
                  svgPath.remove();
                }
                break;

            }

            if (this.configuration.design.animateHotspots) {
              setTimeout(
                () => {
                  if (hotspot.display === 'hover') {
                    svgPath.node.classList.add('animating');
                    svgPath
                      .opacity(1)
                      .animate(Constants.hotspotAnimationDuration)
                      .opacity(0)
                      .after(() => svgPath.node.classList.remove('animating'));
                  }

                  if (hotspot.display === 'hidden') {
                    svgPath
                      .fill({
                        color: this.configuration.design.colors
                          .secondaryBackground,
                        opacity: 1
                      })
                      .animate(Constants.hotspotAnimationDuration)
                      .fill({color: 'transparent', opacity: 0});
                  }
                },
                this.config.layout.pageFlipAnimation
                  ? Constants.pageFlipAnimationDuration / 4
                  : 0
              );
            }
          });
      });
  }

  getBaseUrl() {
    return environment.production ? window.location.href.split('/index.html')[0] + '/' : window.location.origin + '/';
  }

  getFileMetaData(file: number) {
    return this.httpClient.get(this.getBaseUrl() + `${file}_meta.json`);
  }

  buildGalleryElements(file: number, data: any) {
    const dynamicElements: any[] = [];
    if (data?.mimeType?.includes('video')) {
      dynamicElements.push({
        video: {
          source: [{
            src: this.getBaseUrl() + file,
            downloadUrl: this.getBaseUrl() + file,
            type: 'video/mp4',
            size: '1280-720'
          }],
          attributes: {controls: true, preload: 'auto'}
        },
        thumb: this.getBaseUrl() + file + '_thumbnail.jpg',
        downloadUrl: this.getBaseUrl() + file,
      })
    } else {
      dynamicElements.push({
        src: this.getBaseUrl() + file,
        thumb: this.getBaseUrl() + file,
        downloadUrl: this.getBaseUrl() + file,
      })
    }
    return dynamicElements;
  }

  private createInlineGallery(hotspot: Hotspot) {
    let galleryElements = [];
    const observables = [];
    hotspot.action.assets?.forEach(file => {
      observables.push(this.getFileMetaData(file).pipe(map(data => this.buildGalleryElements(file, data))));
    });

    forkJoin(observables).subscribe(results => {
      results.forEach(result => {
        galleryElements = galleryElements.concat(result);
      });
      const lgContainer = document.getElementById('inline-gallery-container');
      if (galleryElements.length > 0 && lgContainer) {
        this.lightgallery = lightGallery(lgContainer, {
          container: lgContainer,
          closable: false,
          plugins: [lgVideo, lgZoom, lgAutoplay],
          loop: true,
          videojs: true,
          counter: !this.isViewerInIframe,
          fullScreen: false,
          autoplay: hotspot.action.autoplay !== undefined && hotspot.action.autoplay !== null ? hotspot.action.autoplay : false,
          slideShowAutoplay: hotspot.action.autoplay !== undefined && hotspot.action.autoplay !== null ? hotspot.action.autoplay : false,
          autoplayControls: true,
          autoplayVideoOnSlide: true,
          progressBar: true,
          gotoNextSlideOnVideoEnd: true,
          easing: 'ease',
          licenseKey: 'AF70A8E9-C51A-4DE3-96EA-5A098931915F',
          controls: !this.isViewerInIframe,
          toolbar: !this.isViewerInIframe,
          videojsOptions: {
            autoplay: false,
            controls: true,
            preload: true
          },
          dynamic: true,
          hash: false,
          showMaximizeIcon: true,
          appendSubHtmlTo: '.lg-item',
          slideDelay: 0,
          thumbnail: false,
          animateThumb: false,
          allowMediaOverlap: true,
          appendAutoplayControlsTo: '.lg-toolbar',
          addClass: 'lg-custom-thumbnails',
          forceSlideShowAutoplay: true,
          mode: 'lg-slide',
          slideShowInterval: hotspot.action.autoplaySpeed !== undefined && hotspot.action.autoplaySpeed !== null && hotspot.action.autoplaySpeed !== 0 ? hotspot.action.autoplaySpeed * 1000 : 10000,
          enableThumbDrag: true,
          enableSwipe: true,
          dynamicEl: galleryElements
        } as any);
        this.lightgallery.openGallery();
        this.toggleFullScreen();
      }
    });
  }

  private removeHotspots() {
    this.document
      .querySelectorAll('p2f-hotspot-layer > .pages > .page')
      .forEach((pageElement: HTMLDivElement) => (pageElement.innerHTML = ''));
  }

  private createInlineHtml(hotspot: Hotspot, isInLine?: boolean, available?: boolean): string {
    const autoplay: string = (hotspot.action.autoplay?.toString() === 'true') ? 'autoplay' : '';
    switch (hotspot.action.type) {
      case 'image':
        if (isInLine) {
          return `<img style='object-fit: contain; box-sizing: border-box; width: 100%; max-height: 100%; overflow: auto;' src="${hotspot.action.target}">`;
        } else {
          return `<img style='max-height:${window.innerHeight - 48}px;max-width:${window.innerWidth - 48}px;aspect-ratio: auto;' src='${hotspot.action.target}'>`;
        }
      case 'gallery':
        return `<div id="inline-gallery-container" class="inline-gallery-container"></div>`;
      case 'video':
        if (this.videoProviders.some(url => hotspot.action.target.includes(url))) {
          const query = (autoplay === 'autoplay') ? {autoplay: true, mute: true} : {autoplay: false};
          return this.sanitizer.sanitize(
            SecurityContext.HTML,
            this.video.embed(hotspot.action.target, {query: query})
          );
        }
        return `<video src="${hotspot.action.target}" ${autoplay} controls></video>`;

      case 'audio':
        return `<audio src="${hotspot.action.target}" ${autoplay} controls></audio>`;

      case 'iframe':
        return `<iframe src="${hotspot.action.target}"></iframe>`;

      case 'article':
        if (isInLine) {
          if (available === false) {
            return `<img style='object-fit: contain; box-sizing: border-box; width: 100%; max-height: 100%; overflow: auto;' src="${hotspot.action.targetDisabled}">`;
          }
          return `<img style='object-fit: contain; box-sizing: border-box; width: 100%; max-height: 100%; overflow: auto;' src="${hotspot.action.target}">`;
        } else {
          return `
          <div class="meta" style="${this.config.isMobile ? 'margin-top: 3rem' : ''}">
            <h2 mat-dialog-title>${hotspot.action.title}</h2>
            <h2 class="price">${this.storage.formatPrice(
            hotspot.action.price,
            true
          )}</h2>
          </div>
          <div class="description">${hotspot.action.description || ''}</div>`;
        }
    }
  }

  private addTooltip(event: MouseEvent, hotspot: Hotspot) {
    if (
      hotspot.action.title ||
      hotspot.action.description ||
      hotspot.action.price
    ) {
      this.tooltip = this.document.createElement('div');
      this.tooltip.classList.add('tooltip');
      if (hotspot.action.title) {
        const title: HTMLSpanElement = this.document.createElement('span');
        title.classList.add('title');
        title.innerHTML = hotspot.action.title;
        this.tooltip.appendChild(title);
      }

      if (hotspot.action.title && hotspot.action.description) {
        const divider: HTMLDivElement = this.document.createElement('div');
        divider.classList.add('divider');
        this.tooltip.appendChild(divider);
      }

      if (hotspot.action.description) {
        const description: HTMLSpanElement = this.document.createElement('span');
        description.classList.add('description');
        description.innerHTML = hotspot.action.description;
        this.tooltip.appendChild(description);
      }

      if (hotspot.action.price) {
        const price: HTMLSpanElement = this.document.createElement('span');
        price.classList.add('price-wrapper');
        this.tooltip.appendChild(price);
      }
      this.document.body.appendChild(this.tooltip);

      const elementWidth: number = this.element.clientWidth;
      const elementHeight: number = this.element.clientHeight;
      const tooltipWidth: number = this.tooltip.clientWidth;
      const tooltipHeight: number = this.tooltip.clientHeight;

      let tooltipX: number = event.clientX + Constants.hotspotTooltipDistance;
      let tooltipY: number = event.clientY + Constants.hotspotTooltipDistance;

      if (elementWidth < tooltipWidth + tooltipX) {
        tooltipX =
          elementWidth - tooltipWidth - Constants.hotspotTooltipDistance;
      }

      if (elementHeight < tooltipHeight + tooltipY) {
        tooltipY =
          elementHeight - tooltipHeight - Constants.hotspotTooltipDistance;
      }

      this.tooltip.style.left = tooltipX + 'px';
      this.tooltip.style.top = tooltipY + 'px';
    }
  }

  private removeTooltip() {
    if (this.tooltip) {
      this.tooltip.remove();
    }
  }

  private openTarget(hotspot: Hotspot, event) {
    this.trackingService.trackEvent({
      category: 'hotspot',
      action: hotspot.action.type,
      custom1: hotspot.page.toString(),
      custom2: hotspot.id.toString(),
      custom3: hotspot.action.title
    });

    switch (hotspot.action.type) {
      case 'gallery':
        if (hotspot.display !== 'inline') {
          this.feature.load('gallery', {
            hotspot: hotspot
          });
        } else {
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      case 'internal':
        this.doc.resetZoom();
        this.doc.gotoPage(parseInt(hotspot.action.target, 0));
        break;

      case 'external':
        // check if URL starts with a supported URI scheme, otherwise prepend http://
        if (
          hotspot.action.target.match(
            new RegExp('^(' + this.uriSchemes.join('|') + ')')
          )
        ) {
          this.window.open(hotspot.action.target);
        } else {
          const strWindowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
          const hw = this.window.open('http://' + hotspot.action.target, 'externalLink', strWindowFeatures);
        }
        break;

      case 'image':
      case 'video':
      case 'audio':
      case 'iframe':
        if (hotspot.display !== 'inline') {
          this.feature.load('hotspot', {
            hotspot: hotspot,
            html: this.createInlineHtml(hotspot)
          });
        }
        break;
      case 'article':
        this.feature.load('hotspot', {
          hotspot: hotspot,
          html: this.createInlineHtml(hotspot)
        });
        break
      case 'feature':
        if (this.configuration.features[hotspot.action.target]) {
          this.feature.load(<PanelType | DialogType>hotspot.action.target);
        }
        break;
    }
  }

  private toggleFullScreen() {
    const fullScreenButton = document.getElementsByClassName('lg-maximize lg-icon');
    Array.from(fullScreenButton).forEach(button => {
      button.addEventListener('click', () => {
        const prevPageBtn = document.getElementById('previous-page-button');
        const nextPageBtn = document.getElementById('next-page-button');
        if (prevPageBtn.style.display === 'none' || nextPageBtn.style.display === 'none') {
          prevPageBtn.style.display = 'inline';
          nextPageBtn.style.display = 'inline';
        } else {
          prevPageBtn.style.display = 'none';
          nextPageBtn.style.display = 'none';
        }
      });
    });
  }

  private subscribeHotspotEvent() {
    this.websocketService.subscribeToHotspotEvents([window['data'].publication.assetId]);
  }

  private subscribeToWebsocketEvents() {
    this.messageEventsSubscription = this.websocketService.hotspotEvents.subscribe(message => {
      switch (message.messageType) {
        case 'HotspotChangedEvent':
          this.handleHotspotChangedEvent(message);
          break;

        case 'HotspotCreatedEvent':
          this.handleHotspotCreatedEvent(message);
          break;

        case 'HotspotDeletedEvent':
          this.handleHotspotDeletedEvent(message);
          break;

        default:
          break;
      }
    });
  }

  private handleHotspotChangedEvent(message: any): void {
    const index = this.hotspots.findIndex(hotspot => hotspot.id === message.hotspot.id);
    if (index !== -1) {
      this.hotspots[index] = message.hotspot as Hotspot;
      this.renderHotspots();
    }
  }

  private handleHotspotCreatedEvent(message: any): void {
    this.hotspots.push(message.hotspot as Hotspot);
    this.renderHotspots();
  }

  private handleHotspotDeletedEvent(message: any): void {
    const index = this.hotspots.findIndex(hotspot => hotspot.id === message.hotspotId);
    if (index !== -1) {
      this.hotspots.splice(index, 1);
      this.renderHotspots();
    }
  }
}


