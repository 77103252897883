import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetItem, Configuration, Hotspot, Instance, Page, PageMarker, Publication } from '@page2flip/core/common';
import { forkJoin, Observable, of, ReplaySubject, Subject, zip } from 'rxjs';
import { catchError, flatMap, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../../../apps/creator/src/environments/environment';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { Action } from '@page2flip/core/common/src/lib/interfaces/hotspot.interface';

/**
 * Service to fetch data from the backend.
 */
@Injectable()
export class DataService {
  private _selectedPageNumber: Subject<number> = new Subject<number>();
  public getSelectedPageNumber$: Observable<number> = this._selectedPageNumber.asObservable();
  private _loadedPublication: Publication;
  private _publicationHotspots: Hotspot[];
  private _publicationPages: Page[];
  private _viewerConfiguration: Configuration;
  private loadedPublicationId: string;
  private publicationLanguage: string = 'de';
  private publicationLoadedSubject: ReplaySubject<string> = new ReplaySubject(1);
  private formatParameter: string = 'page2flip';
  private assetId: string = '';

  get viewerConfiguration(): Configuration {
    return this._viewerConfiguration;
  }

  get publicationHotspots(): Hotspot[] {
    return this._publicationHotspots;
  }

  get publicationPages(): Page[] {
    return this._publicationPages;
  }

  get loadedPublication(): Publication {
    return this._loadedPublication;
  }

  get publicationLoaded(): Observable<string> {
    return this.publicationLoadedSubject.asObservable();
  }

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute
  ) {
    this.route.queryParamMap
      .subscribe((params: ParamMap) => {
        if (params.has('p')) {
          const publicationUrl: string = params.get('p');
          this.formatParameter = publicationUrl.split('/format/')[ 1 ].split('/')[ 0 ];
          this.assetId = publicationUrl.split('/asset/')[ 1 ].split('/')[ 0 ];
        }
      });
  }

  loadMediaAssets(mediaType): Observable<any> {
    return this.httpClient
      .get<AssetItem[]>(`${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/assets`)
      .pipe(
        flatMap(mediaAssets => {
          let calls = [];

          mediaAssets.map(asset => {
            if (asset.meta.itemType === mediaType || (mediaType ==='iframe' && asset.meta.itemType === 'zip')) {
              calls.push(
                this.httpClient.get(`${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ asset.id }`)
              );
            }
          });

          return zip(...calls);
        })
      );
  }

  /**
   * Get all assetItems and filter to get Observable<Publication>.
   */
  loadPublications(): Observable<any> {
    return this.httpClient.get<AssetItem[]>(`${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/assets`)
      .pipe(
        switchMap(assets => {
          const publications$: Observable<Publication>[] = [];
          assets.map(asset => {
              publications$.push(
                this.httpClient.get<Publication>(`${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ asset.id }/format/${ this.formatParameter }/content/data/publication.json`)
                  .pipe(
                    map((response: Publication) => {
                      response.uuid = response.id.toString();
                      response.id = asset.id.toString();
                      return response;
                    }),
                    catchError(e => of(e))
                  )
              );
          });

          return forkJoin(publications$);
        }),
        switchMap(responses => {
          const result: Publication[] = [];
          responses.map(response => {
            if (response.id) {
              result.push(response);
            }
          });

          return of(result);
        })
      );
  }

  async loadPublication(publicationId: string, language?: string) {
    this.loadedPublicationId = publicationId;
    this.publicationLanguage = language;

    const dataUrl = this.publicationUrl(publicationId, 'data/');
    this._loadedPublication = await this.httpClient.get<Publication>(dataUrl + 'publication.json').toPromise();
    this._loadedPublication.id = publicationId;
    this._viewerConfiguration = await this.httpClient.get<Configuration>(dataUrl + 'config.json').toPromise();
    this._publicationHotspots = await this.httpClient.get<Hotspot[]>(dataUrl + 'hotspots.json').toPromise();
    this._publicationPages = await this.httpClient.get<Page[]>(dataUrl + 'pages.json').toPromise();
    this.publicationLoadedSubject.next(publicationId);
  }

  getPublicationHotspots(): Observable<Hotspot[]> {
    const dataUrl = this.publicationUrl(this.loadedPublicationId, 'data/');
    return this.httpClient.get<Hotspot[]>(dataUrl + 'hotspots.json');
  }

  getHotspot(hotspotId: string): Observable<Hotspot> {
    return this.httpClient.get<Hotspot>(`https://proxy.page2flip.de/api/scope/${ environment.spaceOneScope }/asset/${ this.loadedPublicationId }/format/${this.formatParameter}/page2flip/hotspot/${ hotspotId }`);
  }

  createHotspot(hotspot: Hotspot): Observable<any>{
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url: string = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ this.loadedPublicationId }/format/${this.formatParameter}/page2flip/hotspots`;

    return this.httpClient.post<Hotspot>(url, hotspot, {
      headers,
      observe: 'response',
      responseType: 'json'
    });
  }

  updateHotspot(hotspot: Hotspot): Observable<Hotspot>{
    const url: string = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ this.loadedPublicationId }/format/${this.formatParameter}/page2flip/hotspot/${ hotspot.id }`;
    return this.httpClient.put<Hotspot>(url, hotspot);
  }

  deleteHotspot(hotspot: Hotspot): Observable<any>{
    return this.httpClient.delete(`${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ this.loadedPublicationId }/format/${this.formatParameter}/page2flip/hotspot/${ hotspot.id }`, { observe: 'response' });
  }

  unloadPublication() {
    delete this._loadedPublication;
    delete this._viewerConfiguration;
    delete this._publicationHotspots;
    delete this._publicationPages;
  }

  updatePublication(publication: Publication) {
    const patch = [];

    Object.entries(publication.meta).forEach(entry => {
      const key: string = entry[ 0 ];
      const value: string = entry[ 1 ];

      patch.push({
        op: 'replace',
        path: '/meta/' + key,
        value: value
      });
    });

    const id = publication.id || this.loadedPublicationId;
    const url: string = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ id }/page2flip/page2flip/patchjson/data/publication.json`;
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json-patch+json' });

    return this.httpClient.patch(url, patch, {
      headers,
      observe: 'response',
      responseType: 'text'
    });
  }

  updatePublicationHotspots(publication: Publication, hotspots: Hotspot[]) {
    const id = publication.id || this.loadedPublicationId;
    const url: string = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ this.assetId === '' ? id : this.assetId }/page2flip/${this.formatParameter}/patchhotspots`;
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json-patch+json' });

    return this.httpClient.patch(url, hotspots, {
      headers,
      observe: 'response',
      responseType: 'text'
    });
  }

  deletePublication(publication: Publication) {
    return this.httpClient.delete(`${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ publication.id }`, { observe: 'response' });
  }

  publicationUrl(publicationId: string, file?: string): string {
    return `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ this.assetId === '' ? publicationId : this.assetId }/format/${this.formatParameter}/content/${ file }`;
  }

  getAssetThumbnail(id: number) {
    return `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ id }/thumbnail`;
  }

  publicationDownloadUrl(publicationId: string): string {
    return `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ publicationId }/format/${this.formatParameter}`;
  }

  wizardUrl(publicationId: number): string {
    return `/wizard/${ environment.spaceOneInstance }/${ environment.spaceOneScope }/${ publicationId }`;
  }

  getSelectedPageNumber(pageNumber: number) {
    this._selectedPageNumber.next(pageNumber);
  }

  uploadActions(publicationId: string, actionsData: any){
    const headers: HttpHeaders = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });

    let url: string = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ publicationId }/actions`;

    return this.httpClient.post(url, actionsData, {
      headers,
      observe: 'response',
      responseType: 'json'
    });
  }

  loadActions(publicationId: string): Observable<any> {
    return this.httpClient.get(`${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ publicationId }/actions`);
  }

  saveMarkers(publicationId: string, markers: any){
    const headers: HttpHeaders = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });

    let url: string = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ publicationId }/registertabs`;

    return this.httpClient.post(url, markers, {
      headers,
      observe: 'response',
      responseType: 'json'
    });
  }

  getMarkers(publicationId: string): Observable<any> {
    return this.httpClient.get(`${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ publicationId }/registertabs`);
  }

  deletePageMarker(publicationId: string, pageNumber: number): Observable<any>{
    let url: string = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ publicationId }/registertabs`;
    let deleteMarkerPageNumber: string = JSON.stringify({
      "page": pageNumber
    })

    const headers: HttpHeaders = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });

    return this.httpClient.request('delete', url, {body: deleteMarkerPageNumber, headers: headers});
  }

  updateConfigurationPageMarkers(publication: Publication, pageMarkers: PageMarker[]) {
    const id = publication.id || this.loadedPublicationId;
    const url = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ id }/page2flip/p2fdocument/patchjson/data/config.json`;
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json-patch+json' });

    return this.httpClient.patch(url, [{op: 'replace',
        path: 'options/pageMarkers',
        value: pageMarkers}]
      , {
        headers,
        observe: 'response',
        responseType: 'text'
      });
  }

  updateConfigAutoplayInterval(publication: Publication, autoplayInterval: number) {
    const id = publication.id || this.loadedPublicationId;
    const url = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ id }/page2flip/p2fdocument/patchjson/data/config.json`;
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json-patch+json' });

    return this.httpClient.patch(url, [{op: 'replace',
        path: 'autoplay',
        value: autoplayInterval}]
      , {
        headers,
        observe: 'response',
        responseType: 'text'
      });
  }

  updatePageMarkersConfiguration(publication: Publication, value: boolean, key: string) {
    const id = publication.id || this.loadedPublicationId;
    const url = `${ environment.spaceOneApiUrl }/scope/${ environment.spaceOneScope }/asset/${ id }/page2flip/p2fdocument/patchjson/data/config.json`;
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json-patch+json' });

    return this.httpClient.patch(url, [{op: 'replace',
        path: key,
        value: value}]
      , {
        headers,
        observe: 'response',
        responseType: 'text'
      });
  }

  getGalleryVisibilityConfiguration(): Observable<any> {
    return this.httpClient.get(`${ environment.spaceOneApiUrl }/config/page2flip.gallery.${ environment.spaceOneScope }`)
  }

  getArticleVisibilityConfiguration(): Observable<any> {
    return this.httpClient.get(`${ environment.spaceOneApiUrl }/config/page2flip.creator.hotspotTypes.article`)
  }
}
