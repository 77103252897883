import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, retry} from 'rxjs/operators';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket?: WebSocketSubject<any>;

  constructor() {
  }

  get hotspotEvents(): Observable<any> {
    if (!this.socket) {
      this.socket = this.create();
    }

    return this.socket
      .pipe(
        filter(message => {
          if (message.messageType === 'HotspotChangedEvent' || message.messageType === 'HotspotCreatedEvent' || message.messageType === 'HotspotDeletedEvent') {
            return true;
          } else if (message.messageType === 'welcome' && message.authenticated) {
            return false;
          }
          return false;
        }),
        retry()
      );
  }

  private create(): WebSocketSubject<any> {
    return webSocket(environment.websocketUrl);
  }

  subscribeToHotspotEvents(assetIds: number[]) {
    if (this.socket) {
      const message: any = {
        messageType: 'subscription',
        subscriptions: [
          {
            eventType: ['HotspotChangedEvent', 'HotspotCreatedEvent', 'HotspotDeletedEvent'],
            assetIds: assetIds,
            subscriptionType: 'HotspotSubscription'
          }
        ]
      };
      this.socket.next(message);
    }
  }

  closeSocket() {
    this.socket!.unsubscribe();
    this.socket = undefined;
  }
}
